import React, { useEffect, useState, useCallback } from 'react';
import Meta from '../components/Meta';
import { Link,useNavigate } from 'react-router-dom';
import VideoJS from '../components/VideoPlayer';
import { TagAds } from '../components/AdsComponent';
import ErrorRetry, { LoadingText } from '../components/ErrorRetry';
import axios from 'axios';
import {BannerAds,GridAds } from '../components/AdsComponent';
import { LazyImage, placeholder, errorPlaceholder } from '../components/LazyImage';

const DetailVideo = ({ data,relation }) => {
  const { id, title, litpic, playurl, typename, tag, body, series } = data;
  const [fullplayurl, setFullPlayUrl] = useState(null);
  const [tags, setTags] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const playerRef = React.useRef(null);
  const [fileId, setFileId] = useState('');
  const [luj, setLuj] = useState('');
  const navigate = useNavigate();
  
  const fetchUrlsAndTest = useCallback(async () => {
    setLoading(true); // 开始加载
    setError(false); // 重置错误状态

    try {
      getType();
      const response = await axios.get('/c.json');
      let urlPrefixes = "";
      if (data.source == 1){
        urlPrefixes = response.data.playurl2;
      }else{
        urlPrefixes = response.data.playurl;
      }


      let tagsString = data.tag;
      if (tagsString.includes(',')) {
        setTags(tagsString.split(',').map(tag => tag.trim()));
      } else {
        setTags([tagsString.trim()])
      }

      for (const prefix of urlPrefixes) {
        const testUrl = prefix + playurl;
        try {
          await axios.get(testUrl);
          setFullPlayUrl(testUrl);
          const slashIndex = playurl.indexOf('/');
          const fileId = playurl.substring(0, slashIndex);
          if(data.source == 1){
            setFileId("https://8xiy4.xyz/assets/"+fileId+".mp4");
          }else{
            setFileId("https://5gmtf.xyz/mp4/"+fileId+".mp4");
          }
          
          setLoading(false);
          return;
        } catch (e) {
          console.error(`Error Play URL ${testUrl}`);
        }
      }

      setError(true);
    } catch (e) {
      console.error('Error fetching Play URL prefixes:', e);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [playurl]);

  useEffect(() => {
    fetchUrlsAndTest();
  }, [fetchUrlsAndTest]);

  const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    poster: litpic,
    fluid: true,
    sources: [{
      src: fullplayurl,
      type: 'application/x-mpegURL'
    }]
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      // console.log('player is waiting');
    });

    player.on('dispose', () => {
      // console.log('player will dispose');
    });

    player.on('error', () => {
      var error = player.error();
      // console.error('player error:', error.message);
    });
  };

  if (loading) {
    return (
      <LoadingText />
    );
  }

  if (error) {
    return <ErrorRetry title="视频" onClick={fetchUrlsAndTest} />; // onClick={() => window.location.reload()}
  }

  function getType(){
    var sys = {win:false,mac:false,x11:false,ipad:false};
    var p = navigator.platform;
    sys.win = p.indexOf("Win") == 0;
    sys.mac = p.indexOf("Mac") == 0;
    sys.x11 = p.indexOf("X11") == 0;
    sys.ipad = navigator.userAgent.match(/iPad/i) != null ? true :false;
    if(sys.win || sys.mac ||sys.x11 || sys.ipad){
      setLuj("pp");
    }else{
      var a = new Date(new Date().getTime()+(parseInt(new Date().getTimezoneOffset()/60) + 8)*3600*1000);
      var b = new Date();
      if(a.getHours() == b.getHours()){
        setLuj("mm");
      }
    }
  }

  const onc = (n) => {
    if (luj == 'mm') {
      window.location = '/index2.html';
    }else{
      navigate(`/${n}`);
    }
  };

  return (
    <>
      <Meta title={`${title || '精彩视频'}-3B`} description={`在线观看视频-${title || '精彩视频'}`} keywords={`3B,${title || '精彩视频'},${tag || ''}`} />
      <BannerAds />
      <GridAds/>
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4">
        <Link to="/">首页</Link> &gt; <Link to={`/${typename}`}>{typename}</Link>
      </div>
      {playurl ? (
      <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      ) : (
      <div className="max-w-[640px] mx-auto">抱歉，视频已下架</div>
      )}
      <div className="announce bg-tb w-full p-4 md:p-6 text-center mb-4">{title}</div>
      {body && <div className="w-full">{body}</div>}
      {/* {series && <>
        <div className="px-2 my-4">所属专辑：</div>
        <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
          <Link to={`/series/${series}`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">{series}</Link>
        </div>
      </>} */}
      {/* {tag && <>
      <div className="px-2 my-4">相关标签：</div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm">
        {tag.map((t, index) => (
          <Link key={index} to={`/tags/${t}`} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">{t}</Link>
        ))}
        <TagAds />
      </div>
      </>} */}
      <div>
      <div className="px-2 my-4 tags" >相关标签</div>
       {tags.map((t, index) => (
          <Link key={index} to={`/tags/${t}/`} className="tags" style={{color:'black',background:'#ff9900'}}>{t}</Link>
        ))}
      </div>
  
      <div style={{marginTop:'8px'}}>
        <div className="px-2 my-4 tags" >推荐广告</div>
        <TagAds />
      </div>
      <div className="tags_wall flex flex-wrap px-4 mb-6 justify-center gap-1 xs:gap-1 xs:text-xs s:text-sm" style={{marginTop:'8px'}}>
        <a href={fileId} className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">下载本片</a>
        <a href="https://x33gbuz.com" className="border border-tw text-tw px-2 py-1 text-center grow shrink basis-auto">地址找回</a>
      </div>
      
      <div className="title bg-sc text-tb p-2 md:p-4 text-left mb-4" style={{marginTop:'8px'}}>
        推荐视频
      </div>
      <div className="content columns-2 xs:columns-1 s:columns-2 sm:columns-3 md:columns-4 lg:columns-4 gap-2 mb-4">
        {relation.map((item,index) => (
          <div key={item.id} className="break-inside-avoid mb-4">
            <Link to={`/${item.id}/`} className="break-inside-avoid mb-4"   onClick={index === 1 || index === 3 ? (e) => {  e.preventDefault();  onc(item.id);} : undefined}>
              <LazyImage
                className="w-full h-auto object-cover"
                src={item.litpic}
                alt={item.title}
                placeholder={placeholder(300, 200)}
                errorPlaceholder={errorPlaceholder(300, 200, item.title)}
                width={300}
                height={200}
              />
              <p className="text-center mt-2">{item.title}</p>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default DetailVideo;
